.container {

  width: 100%
}

@media (min-width: 0px) {

  .container {

    max-width: 0px
  }
}

@media (min-width: 1px) {

  .container {

    max-width: 1px
  }
}

@media (min-width: 2px) {

  .container {

    max-width: 2px
  }
}

@media (min-width: 3px) {

  .container {

    max-width: 3px
  }
}

@media (min-width: 4px) {

  .container {

    max-width: 4px
  }
}

@media (min-width: 640px) {

  .container {

    max-width: 640px
  }
}

@media (min-width: 768px) {

  .container {

    max-width: 768px
  }
}

@media (min-width: 1024px) {

  .container {

    max-width: 1024px
  }
}

@media (min-width: 1280px) {

  .container {

    max-width: 1280px
  }
}

@media (min-width: 1536px) {

  .container {

    max-width: 1536px
  }
}

  .display-m {

  font-size: 1.25rem;

  font-weight: 700;

  line-height: 1.5rem
}

  @media (min-width: 768px) {

  .display-m {

    font-size: 1.3125rem;

    line-height: 1.625rem
  }
}

  .headline-xl {

  font-size: 1.4375rem;

  line-height: 1.8125rem;

  --tw-text-opacity: 1;

  color: rgb(29 34 40 / var(--tw-text-opacity, 1))
}

  @media (min-width: 768px) {

  .headline-xl {

    font-size: 1.5rem;

    line-height: 1.875rem
  }
}

  .headline-l {

  font-size: 1.3125rem;

  line-height: 1.625rem;

  --tw-text-opacity: 1;

  color: rgb(29 34 40 / var(--tw-text-opacity, 1))
}

  @media (min-width: 768px) {

  .headline-l {

    font-size: 1.25rem
  }
}

  .headline-m {

  font-size: 1.125rem;

  line-height: 1.375rem;

  --tw-text-opacity: 1;

  color: rgb(29 34 40 / var(--tw-text-opacity, 1))
}

  @media (min-width: 768px) {

  .headline-m {

    font-size: 1.0625rem;

    line-height: 1.3125rem
  }
}

  .headline-s {

  line-height: 1.25rem;

  --tw-text-opacity: 1;

  color: rgb(29 34 40 / var(--tw-text-opacity, 1))
}

  @media (min-width: 768px) {

  .headline-s {

    font-size: .9375rem;

    line-height: 1.1875rem
  }
}

  .body-m {

  font-size: .9375rem;

  line-height: 1.25rem
}

  @media (min-width: 768px) {

  .body-m {

    font-size: .875rem;

    line-height: 1.1875rem
  }
}

  .label {

  font-size: .8125rem;

  line-height: 1rem;

  letter-spacing: 0.0125rem;

  --tw-text-opacity: 1;

  color: rgb(91 99 106 / var(--tw-text-opacity, 1))
}

  @media (min-width: 768px) {

  .label {

    font-size: .75rem;

    line-height: .875rem
  }
}

  @media (min-width: 1280px) {

  .label {

    font-size: .8125rem;

    line-height: 1rem
  }
}

  @media (min-width: 640px) {

  .sm\:headline-xl {

    font-size: 1.4375rem;

    line-height: 1.8125rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .sm\:headline-xl {

      font-size: 1.5rem;

      line-height: 1.875rem
    }
  }

  .sm\:headline-m {

    font-size: 1.125rem;

    line-height: 1.375rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .sm\:headline-m {

      font-size: 1.0625rem;

      line-height: 1.3125rem
    }
  }
}

  @media (min-width: 768px) {

  .md\:headline-xl {

    font-size: 1.4375rem;

    line-height: 1.8125rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .md\:headline-xl {

      font-size: 1.5rem;

      line-height: 1.875rem
    }
  }

  .md\:headline-l {

    font-size: 1.3125rem;

    line-height: 1.625rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .md\:headline-l {

      font-size: 1.25rem
    }
  }

  .md\:headline-m {

    font-size: 1.125rem;

    line-height: 1.375rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .md\:headline-m {

      font-size: 1.0625rem;

      line-height: 1.3125rem
    }
  }

  .md\:headline-s {

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .md\:headline-s {

      font-size: .9375rem;

      line-height: 1.1875rem
    }
  }
}

  @media (min-width: 1024px) {

  .lg\:headline-l {

    font-size: 1.3125rem;

    line-height: 1.625rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .lg\:headline-l {

      font-size: 1.25rem
    }
  }

  .lg\:headline-m {

    font-size: 1.125rem;

    line-height: 1.375rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .lg\:headline-m {

      font-size: 1.0625rem;

      line-height: 1.3125rem
    }
  }
}

  @media (min-width: 1280px) {

  .xl\:headline-xl {

    font-size: 1.4375rem;

    line-height: 1.8125rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .xl\:headline-xl {

      font-size: 1.5rem;

      line-height: 1.875rem
    }
  }

  .xl\:headline-m {

    font-size: 1.125rem;

    line-height: 1.375rem;

    --tw-text-opacity: 1;

    color: rgb(29 34 40 / var(--tw-text-opacity, 1))
  }

  @media (min-width: 768px) {

    .xl\:headline-m {

      font-size: 1.0625rem;

      line-height: 1.3125rem
    }
  }
}


:root {
  --uh-max-width: 1504px;
}

@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/f0b1e3f007006b62-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/4c5a69d047c2b2dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/f35b5e6220b536eb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__centra_Fallback_486f22';src: local("Arial");ascent-override: 95.65%;descent-override: 28.69%;line-gap-override: 0.00%;size-adjust: 104.55%
}.__className_486f22 {font-family: '__centra_486f22', '__centra_Fallback_486f22'
}.__variable_486f22 {--font-centra: '__centra_486f22', '__centra_Fallback_486f22'
}

